import request from '@/utils/request'

export function getProduct(query) {
    return request({
        url: '/api/v1/product/product',
        method: 'get',
        params: query,
    })
}

export function getProduct2() {
    return request({
        url: '/api/v1/product/product/lists',
        method: 'get'
    })
}

export function getProductDetail(id) {
    return request({
        url: '/api/v1/product/product/' + id,
        method: 'get'
    })
}

export function getProductFavorite() {
    return request({
        url: '/api/v1/product/favorite',
        method: 'get'
    })
}

export function createFavorite(data) {
    return request({
        url: '/api/v1/product/favorite/create',
        method: 'post',
        data
    })
}

export function updateFavorite(data) {
    return request({
        url: '/api/v1/product/favorite/update',
        method: 'post',
        data
    })
}

export function getKDataAll() {
    return request({
        url: '/api/v1/product/product/kdata',
        method: 'get',
    })
}

export function getKDataList(id, param) {
    return request({
        url: '/api/v1/product/product/kdata/' + id,
        method: 'get',
        params: param
    })
}

export function getPloyList(param) {
    return request({
        url: '/api/v1/product/ploy',
        method: 'get',
        params: param
    })
}


export function getProductContractOrder(query) {
    return request({
        url: '/api/v1/product/contract/order',
        method: 'get',
        params: query,
    })
}

export function getProductCoinOrder(query) {
    return request({
        url: '/api/v1/product/coin/order',
        method: 'get',
        params: query,
    })
}

export function getProductOptionOrder(query) {
    return request({
        url: '/api/v1/product/option/order',
        method: 'get',
        params: query,
    })
}

export function createContractOrder(data) {
    return request({
        url: '/api/v1/product/contract/order/create',
        method: 'post',
        data
    })
}

export function closeContractOrder(data) {
    return request({
        url: '/api/v1/product/contract/order/close',
        method: 'post',
        data
    })
}

export function cancelContractOrder(data) {
    return request({
        url: '/api/v1/product/contract/order/cancel',
        method: 'post',
        data
    })
}

export function getProductContractSetting(id) {
    return request({
        url: '/api/v1/product/contract/order/setting/' + id,
        method: 'get'
    })
}

export function getProductCoinSetting(id) {
    return request({
        url: '/api/v1/product/coin/order/setting/' + id,
        method: 'get'
    })
}

export function getProductOptionSetting(id) {
    return request({
        url: '/api/v1/product/option/order/setting/' + id,
        method: 'get'
    })
}

export function createCoinOrder(data) {
    return request({
        url: '/api/v1/product/coin/order/create',
        method: 'post',
        data
    })
}

export function createOptionOrder(data) {
    return request({
        url: '/api/v1/product/option/order/create',
        method: 'post',
        data
    })
}

export function getProductBasicSetting(id) {
    return request({
        url: '/api/v1/product/product/setting/' + id,
        method: 'get'
    })
}
