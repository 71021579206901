import request from '@/utils/request'

export function getAccount() {
  return request({
    url: '/api/v1/account/account',
    method: 'get'
  })
}

export function getAccountAll() {
  return request({
    url: '/api/v1/account/account/all',
    method: 'get'
  })
}

export function getAccountProduct(id) {
  return request({
    url: '/api/v1/account/account/detail/' + id,
    method: 'get'
  })
}

export function getAccountBill(query) {
  return request({
    url: '/api/v1/account/account/bill',
    method: 'get',
    params: query
  })
}

export function getRechargeList(query) {
  return request({
    url: '/api/v1/account/recharge',
    method: 'get',
    params: query
  })
}

export function getWithdrawList(query) {
  return request({
    url: '/api/v1/account/withdraw',
    method: 'get',
    params: query
  })
}

export function createRecharge(data) {
  return request({
    url: '/api/v1/account/recharge/create',
    method: 'post',
    data
  })
}

export function createWithdraw(data) {
  return request({
    url: '/api/v1/account/withdraw/create',
    method: 'post',
    data
  })
}

export function getBankList() {
  return request({
    url: '/api/v1/account/bank',
    method: 'get'
  })
}

export function createBank(data) {
  return request({
    url: '/api/v1/account/bank/create',
    method: 'post',
    data
  })
}

export function getAddressList(query) {
  return request({
    url: '/api/v1/account/address',
    method: 'get',
    params: query
  })
}

export function createAddress(data) {
  return request({
    url: '/api/v1/account/address/create',
    method: 'post',
    data
  })
}

export function getLoanList() {
  return request({
    url: '/api/v1/loan/loan',
    method: 'get'
  })
}

export function createLoan(data) {
  return request({
    url: '/api/v1/loan/loan/create',
    method: 'post',
    data
  })
}

export function getLoanRepayList() {
  return request({
    url: '/api/v1/loan/repay',
    method: 'get'
  })
}

export function createLoanRepay(data) {
  return request({
    url: '/api/v1/loan/repay/create',
    method: 'post',
    data
  })
}

export function createTransfer(data) {
  return request({
    url: '/api/v1/account/account/transfer',
    method: 'post',
    data
  })
}

export function createTransferBit(data) {
  return request({
    url: '/api/v1/account/account/bit-transfer',
    method: 'post',
    data
  })
}
