import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/home/web'),
  },
  {
    path: '/noPermissions',
    name: 'noPermissions',
    component: () => import('@/views/noPermissions/noPermissions'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register'),
  },
  {
    path: '/products/forex',
    name: 'products',
    component: () => import('@/views/products/forex'),
  },
  {
    path: '/products/indices',
    name: 'products',
    component: () => import('@/views/products/indices'),
  },
  {
    path: '/products/commodities',
    name: 'products',
    component: () => import('@/views/products/commodities'),
  },
  {
    path: '/products/shares',
    name: 'products',
    component: () => import('@/views/products/shares'),
  },
  {
    path: '/products/coins',
    name: 'products',
    component: () => import('@/views/products/coins'),
  },
  {
    path: '/trade/platforms',
    name: 'trade',
    component: () => import('@/views/trade/platforms'),
  },
  {
    path: '/trade/analysis',
    name: 'trade',
    component: () => import('@/views/trade/analysis'),
  },
  {
    path: '/trade/forecast',
    name: 'trade',
    component: () => import('@/views/trade/forecast'),
  },
  {
    path: '/trade/calendar',
    name: 'trade',
    component: () => import('@/views/trade/calendar'),
  },
  {
    path: '/trade/news',
    name: 'trade',
    component: () => import('@/views/trade/news'),
  },
  {
    path: '/trade/quotes',
    name: 'trade',
    component: () => import('@/views/trade/quotes'),
  },
  {
    path: '/trade/sentiment',
    name: 'trade',
    component: () => import('@/views/trade/sentiment'),
  },
  {
    path: '/trade/risk',
    name: 'trade',
    component: () => import('@/views/trade/risk'),
  },
  {
    path: '/trade/contract',
    name: 'trade',
    component: () => import('@/views/trade/contract'),
  },
  {
    path: '/learn/education',
    name: 'learn',
    component: () => import('@/views/learn/education'),
  },
  {
    path: '/about/mitrade',
    name: 'about',
    component: () => import('@/views/about/mitrade'),
  },
  {
    path: '/about/awards',
    name: 'about',
    component: () => import('@/views/about/awards'),
  },
  {
    path: '/about/media',
    name: 'about',
    component: () => import('@/views/about/media'),
  },
  {
    path: '/about/fundSecurity',
    name: 'about',
    component: () => import('@/views/about/fundSecurity'),
  },
  {
    path: '/about/charges',
    name: 'about',
    component: () => import('@/views/about/charges'),
  },
  {
    path: '/about/contact',
    name: 'about',
    component: () => import('@/views/about/contact'),
  },
  {
    path: '/about/faq',
    name: 'about',
    component: () => import('@/views/about/faq'),
  },
  {
    path: '/about/help',
    name: 'about',
    component: () => import('@/views/about/help'),
  },
  {
    path: '/web/trade',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/web/trade',
        component: () => import('@/views/web/trade')
      },
      {
        path: '/web/markets',
        component: () => import('@/views/web/markets')
      },
      {
        path: '/web/news',
        component: () => import('@/views/web/news')
      },
      {
        path: '/web/account',
        component: () => import('@/views/web/account')
      },
      {
        path: '/web/loan',
        component: () => import('@/views/web/loan')
      }
    ]
  },
]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}


const router = new VueRouter({
  routes
})

export default router
